import React from 'react';
import FormField from '@ingka/form-field';
import InputField from '@ingka/input-field';
import styled from 'styled-components';
import { useDisclosure } from 'utils/hooks';
import Button from '@ingka/button';
import Modal, { Prompt, ModalFooter } from '@ingka/modal';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { customersAPI } from 'api/customersAPI';
import { refetchCustomersQueryMutationOption } from 'pages/Customers/refetchCustomersOption';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useToast } from 'components/ToastAnchor';
import { CustomerDto } from 'api/genTypes/customer';

const FormContainer = styled.form`
  width: 312px;
`;

const isPhoneNumberValid = (n: string) => n.length <= 12 && n.length >= 8;

type FormData = Omit<CustomerDto, 'id' | 'isBusinessCustomer'>;

const CustomerFormInputs = ({
  formFieldRegister,
  formState,
  customer,
}: {
  formFieldRegister: UseFormReturn<FormData>['register'];
  formState: UseFormReturn<FormData>['formState'];
  customer?: CustomerDto;
}) => {
  const { isSubmitted, errors } = formState;
  return (
    <FormContainer>
      <FormField
        valid={!errors.firstName}
        shouldValidate={isSubmitted}
        validation={{
          msg: 'Invalid first name - it must be longer than 3 characters and less than 20 characters',
          type: 'error',
        }}
      >
        <InputField
          type="text"
          id="firstName"
          data-testid="firstName"
          label="First name"
          maxLength={20}
          defaultValue={customer?.firstName}
          {...formFieldRegister('firstName', { minLength: 4, maxLength: 20, required: true })}
        />
      </FormField>
      <FormField
        valid={!errors.lastName}
        shouldValidate={isSubmitted}
        validation={{
          msg: 'Invalid last name - it must be longer than 3 characters and less than 20 characters',
          type: 'error',
        }}
      >
        <InputField
          type="text"
          id="lastName"
          label="Last name"
          data-testid="lastName"
          defaultValue={customer?.lastName}
          {...formFieldRegister('lastName', { minLength: 4, maxLength: 20, required: true })}
        />
      </FormField>
      <FormField
        valid={!errors.emailAddress}
        shouldValidate={isSubmitted}
        validation={{
          msg: 'Invalid email address',
          type: 'error',
        }}
      >
        <InputField
          type="text"
          id="emailAddress"
          data-testid="emailAddress"
          label="Email address"
          defaultValue={customer?.emailAddress}
          {...formFieldRegister('emailAddress', {
            pattern:
              /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/,
            required: true,
          })}
        />
      </FormField>
      <FormField
        valid={!errors.phoneNumber}
        shouldValidate={isSubmitted}
        validation={{
          msg: 'Phone number length must be longer than 7 and less than 13',
          type: 'error',
        }}
      >
        <InputField
          type="text"
          id="phoneNumber"
          data-testid="phoneNumber"
          defaultValue={customer?.phoneNumber}
          label="Phone number"
          {...formFieldRegister('phoneNumber', { validate: isPhoneNumberValid })}
        />
      </FormField>
    </FormContainer>
  );
};

export const AddCustomerFormModal = () => {
  const modal = useDisclosure();
  const addCustomerForm = useForm<FormData>();
  const queryClient = useQueryClient();
  const createMutation = useMutation(
    customersAPI.create,
    refetchCustomersQueryMutationOption(queryClient)
  );
  const toast = useToast();

  const appInsights = useAppInsightsContext();
  const trackCustomerAdded = useTrackEvent(appInsights, 'Customer Added', {});

  const onAddCustomer = (c: FormData) => {
    const customerData = { ...c, isBusinessCustomer: false };
    return createMutation
      .mutateAsync(customerData)
      .then(resetFormAndCloseModal)
      .then(() => trackCustomerAdded(customerData))
      .catch((err: Error) => toast.open(err.message, 'ERROR'));
  };

  const resetFormAndCloseModal = () => {
    addCustomerForm.reset();
    modal.close();
  };

  return (
    <>
      <Button type="primary" onClick={modal.open}>
        Add new customer
      </Button>
      <Modal handleCloseBtn={resetFormAndCloseModal} visible={modal.isOpen}>
        <Prompt
          title={<>Add new customer</>}
          footer={
            <ModalFooter compact>
              <Button small onClick={resetFormAndCloseModal} type="secondary">
                Cancel
              </Button>
              <Button
                small
                onClick={addCustomerForm.handleSubmit(onAddCustomer)}
                type="primary"
                disabled={
                  addCustomerForm.formState.isSubmitted && !addCustomerForm.formState.isValid
                }
                loading={createMutation.isLoading}
              >
                Submit
              </Button>
            </ModalFooter>
          }
        >
          <p>Please fill in the details for the customer</p>
          <br />
          <CustomerFormInputs
            formFieldRegister={addCustomerForm.register}
            formState={addCustomerForm.formState}
          />
        </Prompt>
      </Modal>
    </>
  );
};

export const EditCustomerFormModal = (props: { customer: CustomerDto }) => {
  const modal = useDisclosure();
  const editCustomerForm = useForm<FormData>();
  const queryClient = useQueryClient();
  const updateMutation = useMutation(
    customersAPI.update,
    refetchCustomersQueryMutationOption(queryClient)
  );
  const toast = useToast();

  const resetFormAndCloseModal = () => {
    editCustomerForm.reset();
    modal.close();
  };

  const appInsights = useAppInsightsContext();

  const trackCustomerEdited = useTrackEvent(appInsights, 'Customer Data Updated', {});

  const filteroutNullFields = <T extends Object>(inputObj: T) =>
    Object.fromEntries(Object.entries(inputObj).filter(([, v]) => v));

  const updateCustomer = (c: CustomerDto) =>
    updateMutation
      .mutateAsync({
        ...filteroutNullFields(props.customer),
        ...editCustomerForm.getValues(),
      })
      .then(resetFormAndCloseModal)
      .then(() => trackCustomerEdited(c))
      .catch((err: Error) => toast.open(err.message, 'ERROR'));

  return (
    <>
      <Button type="secondary" small onClick={modal.open}>
        Edit
      </Button>
      <Modal handleCloseBtn={resetFormAndCloseModal} visible={modal.isOpen}>
        <Prompt
          title={<>Edit Customer</>}
          footer={
            <ModalFooter compact>
              <Button small onClick={resetFormAndCloseModal} type="secondary">
                Cancel
              </Button>
              <Button
                small
                onClick={editCustomerForm.handleSubmit(updateCustomer)}
                type="primary"
                loading={updateMutation.isLoading}
                disabled={!editCustomerForm.formState.isDirty}
              >
                Submit
              </Button>
            </ModalFooter>
          }
        >
          <p>Please update the fields and then click submit</p>
          <br />
          <CustomerFormInputs
            formFieldRegister={editCustomerForm.register}
            formState={editCustomerForm.formState}
            customer={props.customer}
          />
        </Prompt>
      </Modal>
    </>
  );
};
